<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <p>{{ company.company_name }}, {{ company.address }}, {{ company.company_email }}, {{ company.phone }}</p>
                    <p>THIS NOTICE DESCRIBES HOW HEALTH INFORMATION MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</p>
                    <h3>I. MY PLEDGE REGARDING HEALTH INFORMATION:</h3>
                    <p>I understand that health information about you and your health care is personal. I am committed to protecting health information about you. I create a record of the care and services you receive from me. I need this record to provide you with quality care and to comply with certain legal requirements. This notice applies to all of the records of your care generated by this practice. This notice will tell you about the ways in which I may use and disclose health information about you. I also describe your rights to the health information I keep about you and describe certain obligations I have regarding the use and disclosure of your health information. I am required by law to:</p>
                    <ul class="avoid-break">
                        <li>Make sure that protected health information (“PHI”) that identifies you is kept private.</li>
                        <li>Give you this notice of my legal duties and privacy practices with respect to health information.</li>
                        <li>Follow the terms of the notice that is currently in effect.</li>
                        <li>I can change the terms of this Notice, and such changes will apply to all information I have about you. The new Notice will be available upon request, in my office, and on my website.</li>
                    </ul>
                    <h3>II. HOW I MAY USE AND DISCLOSE HEALTH INFORMATION ABOUT YOU:</h3>
                    <p class="avoid-break">The following categories describe different ways that I use and disclose health information. For each category of uses or disclosures I will explain what I mean and try to give some examples. Not every use or disclosure in a category will be listed. However, all the ways I am permitted to use and disclose information will fall within one of the categories.</p>
                    <p class="avoid-break">For Treatment Payment, or Health Care Operations: Federal privacy rules (regulations) allow health care providers who have direct treatment relationship with the patient/client to use or disclose the patient/client’s personal health information without the patient’s written authorization, to carry out the health care provider’s own treatment, payment or health care operations. I may also disclose your protected health information for the treatment activities of any health care provider. This too can be done without your written authorization. For example, if a clinician were to consult with another licensed health care provider about your condition, we would be permitted to use and disclose your person health information, which is otherwise confidential, in order to assist the clinician in diagnosis and treatment of your condition. Clinicians maintain the right to consult regarding clients within the practice.</p>
                    <p class="avoid-break">Disclosures for treatment purposes are not limited to the minimum necessary standard. Because clinicians and other health care providers need access to the full record and/or full and complete information in order to provide quality care. The word “treatment” includes, among other things, the coordination and management of health care providers with a third party, consultations between health care providers and referrals of a patient for health care from one health care provider to another.</p>
                    <p class="avoid-break">Lawsuits and Disputes: If you are involved in a lawsuit, I may disclose health information in response to a court or administrative order. I may also disclose health information about your child in response to a subpoena, discovery request, or other lawful process by someone else involved in the dispute, but only if efforts have been made to tell you about the request or to obtain an order protecting the information requested.</p>
                    <div class="avoid-break">
                        <h3>III. CERTAIN USES AND DISCLOSURES REQUIRE YOUR AUTHORIZATION:</h3>
                        <ol>
                            <li>Psychotherapy Notes. I do keep “psychotherapy notes” and any use or disclosure of such notes requires your Authorization unless the use or disclosure is:
                                <ol style="list-style-type: lower-alpha;">
                                    <li>For my use in treating you.</li>
                                    <li>For my use in training or supervising associates to help them improve their skills.</li>
                                    <li>For my use in defending myself in legal proceedings instituted by you.</li>
                                    <li>For use by the Secretary of Health and Human Services to investigate my compliance with HIPAA.</li>
                                    <li>Required by law and the use or disclosure is limited to the requirements of such law.</li>
                                    <li>Required by law for certain health oversight activities pertaining to the originator of the psychotherapy notes.</li>
                                    <li>Required by a coroner who is performing duties authorized by law.</li>
                                    <li>Required to help avert a serious threat to the health and safety of others.</li>
                                </ol>
                            </li>
                            <li>Marketing Purposes. As a clinician, I will not use or disclose your PHI for marketing purposes.</li>
                            <li>Sale of PHI. As a clinician, I will not sell your PHI in the regular course of my business.</li>
                        </ol>
                    </div>
                    <div class="avoid-break">
                        <h3>IV. CERTAIN USES AND DISCLOSURES DO NOT REQUIRE YOUR AUTHORIZATION.</h3>
                        <p>Subject to certain limitations in the law, I can use and disclose your PHI without your Authorization for the following reasons:</p>
                    </div>
                    <ol>
                        <li class="avoid-break">When disclosure is required by state or federal law, and the use or disclosure complies with and is limited to the relevant requirements of such law.</li>
                        <li class="avoid-break">For public health activities, including reporting suspected child, elder, or dependent adult abuse, or preventing or reducing a serious threat to anyone’s health or safety.</li>
                        <li class="avoid-break">For health oversight activities, including audits and investigations.</li>
                        <li class="avoid-break">For judicial and administrative proceedings, including responding to a court or administrative order, although my preference is to obtain an Authorization from you before doing so.</li>
                        <li class="avoid-break">For law enforcement purposes, including reporting crimes occurring on my premises.</li>
                        <li class="avoid-break">To coroners or medical examiners, when such individuals are performing duties authorized by law.</li>
                        <li class="avoid-break">For research purposes, including studying and comparing the patients who received one form of therapy versus those who received another form of therapy for the same condition.</li>
                        <li class="avoid-break">Specialized government functions, including, ensuring the proper execution of military missions; protecting the President of the United States; conducting intelligence or counter-intelligence operations; or, helping to ensure the safety of those working within or housed in correctional institutions.</li>
                        <li class="avoid-break">For workers’ compensation purposes. Although my preference is to obtain an Authorization from you, I may provide your PHI in order to comply with workers’ compensation laws.</li>
                        <li class="avoid-break">Appointment reminders and health related benefits or services. I may use and disclose your PHI to contact you to remind you that you have an appointment with me. I may also use and disclose your PHI to tell you about treatment alternatives, or other health care services or benefits that I offer.</li>
                    </ol>
                    <div class="avoid-break">
                        <h3>V. CERTAIN USES AND DISCLOSURES REQUIRE YOU TO HAVE THE OPPORTUNITY TO OBJECT.</h3>
                        <h3 class="avoid-break">Disclosures to family, friends, or others. I may provide your PHI to a family member, friend, or other person that you indicate is involved in your care or the payment for your health care, unless you object in whole or in part. The opportunity to consent may be obtained retroactively in emergency situations.</h3>
                    </div>
                    <h3>VI. YOU HAVE THE FOLLOWING RIGHTS WITH RESPECT TO YOUR PHI:</h3>
                    <ol>
                        <li class="avoid-break">The right to request limits on uses and disclosures of Your PHI. You have the right to ask me not to use or disclose certain PHI for treatment, payment, or health care operations purposes. I am not required to agree to your request, and I may say “no” if I believe it would affect your health care.</li>
                        <li class="avoid-break">The right to request restrictions for out-of-pocket expenses paid for in full. You have the right to request restrictions on disclosures of your PHI to health plans for payment or health care operations purposes if the PHI pertains solely to a health care item or a health care service that you have paid for out-of-pocket in full.</li>
                        <li class="avoid-break">The right to choose how I send PHI to you. You have the right to ask me to contact you in a specific way (for example, home or office phone) or to send mail to a different address, and I will agree to all reasonable requests.</li>
                        <li class="avoid-break">The right to see and get copies of your PHI. Other than “psychotherapy notes,” you have the right to get an electronic or paper copy of your medical record and other information that I have about you. I will provide you with a copy of your record, or a summary of it, if you agree to receive a summary, within 30 days of receiving your written request, and I may charge a reasonable, cost-based fee for doing so.</li>
                        <li class="avoid-break">The right to get a list of the disclosures I have made. You have the right to request a list of instances in which I have disclosed your PHI for purposes other than treatment, payment, or health care operations, or for which you provided me with an Authorization. I will respond to your request for an accounting of disclosures within 60 days of receiving your request. The list I will give you will include disclosures made in the last six years unless you request a shorter time. I will provide the list to you at no charge, but if you make more than one request in the same year, I will charge you a reasonable cost-based fee for each additional request.</li>
                        <li class="avoid-break">The right to correct or update your PHI. If you believe that there is a mistake in your PHI, or that a piece of important information is missing from your PHI, you have the right to request that I correct the existing information or add the missing information. I may say “no” to your request, but I will tell you why in writing within 60 days of receiving your request.</li>
                        <li class="avoid-break">The right to get a paper or electronic copy of this Notice. You have the right get a paper copy of this Notice, and you have the right to get a copy of this notice by e-mail. And, even if you have agreed to receive this Notice via e-mail, you also have the right to request a paper copy of it.</li>
                    </ol>
                    <div class="avoid-break">
                        <h3>EFFECTIVE DATE OF THIS NOTICE</h3>
                        <p>This notice went into effect on {{ dateSigned }}</p>
                        <p>Acknowledgement of Receipt of Privacy Notice</p>
                        <p>Under the Health Insurance Portability and Accountability Act of 1996 (HIPAA), you have certain rights regarding the use and disclosure of your protected health information. By signing below, you are acknowledging that you have received a copy of HIPAA Notice of Privacy Practices.</p>
                        <p>BY SIGNING BELOW, I AM AGREEING THAT I HAVE READ, UNDERSTOOD AND AGREE TO THE ITEMS CONTAINED IN THIS DOCUMENT.</p>
                    </div>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'
    import dayjs from 'dayjs';

    export default {
        name: 'NoticeOfPrivacyPractices',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        computed: {
            dateSigned() {
                let dos = '{date_of_signature}';
                if (this.signature_ids.length > 0) {
                    dos = dayjs(this.signature_ids[0].signedAt).format('YYYY-MM-DD');
                }
                return dos;
            },
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>